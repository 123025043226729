@charset "utf-8";

@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partialsbash-5.1

figure {
  font-family: $global-font-family;
  figcaption {
    font-family: $global-font-family;
  }
}

figure.third {
  font-size: $type-size-5;
  figcaption {
    font-size: $type-size-5;
  }
}

nav.breadcrumbs {
  text-transform: capitalize;
}

.mainpage {
  .archive__item-teaser {
    img {
      width: 90px;
    }
  }
}
.layout--home {
  .page__title {
    display: none;
  }
}

.feature__wrapper {
    border-bottom: none;
}
